@media screen and (max-width: 400px) {
  #features {    
    width: 100%;
    max-width: 100%;
  }
  #about{
    width: 100%;
    max-width: 100%;
    padding-bottom: 20px;
    padding-top: 0px;
  }

  #contact{
    width: 100%;
    max-width: 100%;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  #services,
  #testimonials,
  #team,
  #footer {    
    width: 100%;
    max-width: 100%;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  #portfolio {
    width: 110%;
  }
  #header-intro {
    padding-top: 120px;
    padding-bottom: 0px;
    text-align: center;
  }

  #header-intro-h1 {
    font-size: 40px;
  }
}

.app-icons {
  display: flex;
  justify-content: start;
  align-items: center;
}
.vertical {
  width: 300px;
  flex-direction: column;
}

.android {
  width: 200px;
}
.apple {
  width: 164px;
}